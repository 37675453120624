// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_logo__1Ev9K {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  font-family: \"Roboto\", serif;\n}\n\n.Logo_logo__1Ev9K div {\n  display: flex;\n  flex-direction: column;\n  margin-left: 20px;\n}\n\n.Logo_logo__1Ev9K span:first-child {\n  font-size: 16px;\n  margin-bottom: 5px;\n  font-weight: 300;\n}\n\n.Logo_logo__1Ev9K span:first-child b {\n  font-weight: bold !important;\n}\n\n.Logo_logo__1Ev9K span:nth-child(2) {\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 18px;\n  margin-bottom: 5px;\n}\n\n.Logo_logo__1Ev9K span:last-child {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 16px;\n}", "",{"version":3,"sources":["webpack://src/components/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".logo {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  font-family: \"Roboto\", serif;\n}\n\n.logo div {\n  display: flex;\n  flex-direction: column;\n  margin-left: 20px;\n}\n\n.logo span:first-child {\n  font-size: 16px;\n  margin-bottom: 5px;\n  font-weight: 300;\n}\n\n.logo span:first-child b {\n  font-weight: bold !important;\n}\n\n.logo span:nth-child(2) {\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 18px;\n  margin-bottom: 5px;\n}\n\n.logo span:last-child {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Logo_logo__1Ev9K"
};
export default ___CSS_LOADER_EXPORT___;
