// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TypeformDemand_mainSection__3RJh- {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    padding: 35px 290px 85px 290px;\n    min-height: calc(100vh - 120px);\n}\n\n#TypeformDemand_typeform__2VZ00 {\n    min-height: 500px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/TypeformDemand/TypeformDemand.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,8BAA8B;IAC9B,+BAA+B;AACnC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".mainSection {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    padding: 35px 290px 85px 290px;\n    min-height: calc(100vh - 120px);\n}\n\n#typeform {\n    min-height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSection": "TypeformDemand_mainSection__3RJh-",
	"typeform": "TypeformDemand_typeform__2VZ00"
};
export default ___CSS_LOADER_EXPORT___;
