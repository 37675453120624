import styles from './MatchedSolutions.module.css'
import { Breadcumb, Button, SolutionCard } from "../../components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ChallengesService from "../../services/ChallengesService";

const MatchedSolutions = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [solutions, setSolutions] = useState(null)
    const [challenge, setChallenge] = useState(null)
    const params = useParams()

    useEffect(() => {
        const fetchSolutions = async () => {
            setIsLoading(true)
            const response = await ChallengesService.getSolutions(params.id)
            setSolutions(response.data.json)
            setIsLoading(false)
        }
        fetchSolutions().then()
    }, [])

    useEffect(() => {
        const fetchChallenge = async () => {
            const response = await ChallengesService.getChallengeInfo(params.id)
            setChallenge(response.data.json)
        }
        fetchChallenge().then()
    }, [])

    return (
        <>
            <Breadcumb links={[{
                title: 'Challenges',
                link: '/challenges'
            }, {
                title: 'Current challenge',
                link: `/challenges/${params.id}`
            }, {
                title: 'Challenge demand',
                link: `/challenges/${params.id}/demand`
            }, {
                title: 'Proposed solutions',
                link: `/challenges/${params.id}/demand/proposedsolutions`
            }]} />
            <section className={styles.mainSection}>
                <div className={styles.wrapper}>
                    <div className={styles.wrapperHeader}>
                        <div className={styles.textBlock}>
                            <span>Proposed Solutions for {challenge ? challenge["What is the Challenge's name?"] : 'challenge'}</span>
                            {challenge ? <span>Last updated {new Date(challenge.Modified).toLocaleDateString()}</span> : null}
                        </div>
                    </div>
                    <hr />
                    <div>
                        {isLoading ?
                            <p>Loading</p> :
                            solutions ?
                                solutions.map(solution => (
                                    <SolutionCard key={solution.id} solution={solution} />
                                )) :
                                null
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default MatchedSolutions