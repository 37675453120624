import styles from './HeaderProfileLink.module.css';
import { BsCaretDownFill } from "react-icons/all";

const HeaderProfileLink = ({ username }) => {
    return (
        <div className={styles.header__profilelink}>
            <div className={styles.header__avatar} />
            <span className={styles.header__link_combined}>{username} <BsCaretDownFill /></span>
        </div>
    )
}

export default HeaderProfileLink