import styles from './DemandCard.module.css'
import { useHistory } from "react-router-dom";
import { SpanText } from "../index";

const DemandCard = ({ results }) => {
    const history = useHistory()

    const toDemandDetails = () => {
        history.push(`/demandform/${results.ChallengeId}/${results.id}`);
    }

    // const getLines = (results) => {
    //     const hiden = ['id', 'ChallengeId', 'ChallengeName', 'Created', 'Modified', 'Owner', 'isDemo']
    //     const tmpLines = []
    //     for (const key in results) {
    //         if (!hiden.includes(key)) {
    //             tmpLines.push(
    //                 <p className={styles.info}>{key} <span className={styles.greenText}>{results[key]}</span>
    //                 </p>
    //             )
    //         }
    //     }
    //     return tmpLines
    // }

    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.nameAndDate}>
                    <span className={styles.challengeName}>{results.ChallengeName}</span>
                    <span className={styles.date}>Last updated {new Date(results.Modified).toLocaleDateString()}</span>
                </div>
                <button className={styles.button} onClick={toDemandDetails}>Go to demand</button>
            </div>
            <hr className={styles.hr} />
            <span className={styles.desc}>{results["Which *type of climate action* could you take forward?"] || results["1.Where do you want to *create or improve Access to*?"]}</span>
            <p className={styles.info}>Submitted by <span className={styles.greenText}>{results["Please provide the name of the city or entity you represent"]}</span></p>
            <p className={styles.info}>Submission type <span className={styles.greenText}>Demand</span></p>
        </div>
    )
}

export default DemandCard