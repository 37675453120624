// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__1VCKf {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 30px 75px;\n\n    max-height: 120px;\n    background: #293D6B;\n    color: #FFFFFF;\n    -webkit-user-select: none;\n            user-select: none;\n}\n\n.Header_rightBlock__3rWkS {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.Header_rightBlock__3rWkS nav {\n    margin-right: 70px;\n}", "",{"version":3,"sources":["webpack://src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;;IAElB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 30px 75px;\n\n    max-height: 120px;\n    background: #293D6B;\n    color: #FFFFFF;\n    user-select: none;\n}\n\n.rightBlock {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.rightBlock nav {\n    margin-right: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__1VCKf",
	"rightBlock": "Header_rightBlock__3rWkS"
};
export default ___CSS_LOADER_EXPORT___;
