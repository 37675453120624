import styles from './Button.module.css'

const Button = ({caption, type = 'primary', subtitle, onClick = () => {}, subtitleClick = () => {}}) => {
    let className;
    switch (type) {
        case 'primary':
            className = 'buttonPrimary'
            break;
        case "secondary":
            className = 'buttonSecondary'
            break;
        case "info":
            className = 'buttonInfo'
            break;
        case "basic":
            className = 'buttonBasic'
            break;
        case "black":
            className = 'buttonBlack'
            break;
        case "link":
            className = 'buttonLink'
            break;
        case "linkLogout":
            className = 'buttonLinkLogout'
            break;
        case "basicSecondary":
            className = 'buttonBasicSecondary'
            break;
        default:
            className = 'buttonPrimary'
    }

    if(subtitle) {
        return (
            <div className={styles.block}>
                <button className={`${styles.button} ${styles[className]}`} onClick={onClick}>{caption}</button>
                <span onClick={subtitleClick}>{subtitle}</span>
            </div>
        )
    }

    return (
            <button className={`${styles.button} ${styles[className]}`} onClick={onClick}>{caption}</button>
    )
}

export default Button