// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H2_h2__3yN3g {\n    font-style: normal;\n    font-weight: 500;\n    font-size: 22px;\n    line-height: 28px;\n}", "",{"version":3,"sources":["webpack://src/components/H2/H2.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".h2 {\n    font-style: normal;\n    font-weight: 500;\n    font-size: 22px;\n    line-height: 28px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h2": "H2_h2__3yN3g"
};
export default ___CSS_LOADER_EXPORT___;
