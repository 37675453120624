import styles from './AnswerForm.module.css'
import { QuestionCard } from "../../components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ChallengesService from "../../services/ChallengesService";
import SolutionsService from "../../services/SolutionsService";
import DemandsService from "../../services/DemandsService";

const AnswerForm = ({ type }) => {
    const [results, setResults] = useState(null)
    const [challenge, setChallenge] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const params = useParams()

    useEffect(() => {
        setIsLoading(true)
        const fetchChallenge = async () => {
            const response1 = await ChallengesService.getChallengeInfo(params.formId)
            setChallenge(response1.data.json)
            console.log()
            let response2;
            if (type === 'solution') {
                response2 = await SolutionsService.getSolutionById(params.responseId)
            } else if (type === 'demand') {
                response2 = await DemandsService.getDemandById(params.responseId)
            } else {
                response2 = response1
            }
            setResults(response2.data.json)
        }
        fetchChallenge().then(() => setIsLoading(false))
    }, [])

    const questionLines = (results) => {
        const hiden = ['id', 'ChallengeId', 'ChallengeName', 'Created', 'Modified', 'Owner', 'isDemo']
        const tmpCards = []
        for (const key in results) {
            if (!hiden.includes(key)) {
                tmpCards.push(<QuestionCard question={key} answer={results[key]} />)
            }
        }
        return tmpCards
    }

    return (
        <div className={styles.mainWrap}>
            <div className={styles.wrapper} id={"wrapper"}>
                <div className={styles.wrapperHeader}>
                    <div className={styles.textBlock}>
                        {type === 'challenge' ?
                            <span>{challenge ? challenge["What is the Challenge's name?"] : null}</span> :
                            <span>{type === 'demand' ? 'Demand' : 'Solution'} Submission: {challenge ? challenge["What is the Challenge's name?"] : null}</span>
                        }
                        <span>Last updated {results ? new Date(challenge.Modified).toLocaleDateString() : null}</span>
                    </div>
                </div>
                <hr />
                {
                    <div className={styles.cards}>
                        {results ?
                            questionLines(results) :
                            null
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default AnswerForm