// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_descriptionBlock__1d5p6 {\n    padding: 30px 315px;\n    background: #C4C4C4;\n}\n\n.Footer_descriptionBlock__1d5p6 p {\n    display: flex;\n    flex-direction: column;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 15px;\n}\n\n.Footer_descriptionBlock__1d5p6 p b {\n     font-weight: 600;\n }\n\n.Footer_descriptionBlock__1d5p6 p a {\n    text-decoration: none;\n}\n\n.Footer_blueLine__2CfFi {\n    width: 100%;\n    height: 20px;\n    background-color: #293D6B;\n}", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;KACK,gBAAgB;CACpB;;AAED;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".descriptionBlock {\n    padding: 30px 315px;\n    background: #C4C4C4;\n}\n\n.descriptionBlock p {\n    display: flex;\n    flex-direction: column;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 15px;\n}\n\n.descriptionBlock p b {\n     font-weight: 600;\n }\n\n.descriptionBlock p a {\n    text-decoration: none;\n}\n\n.blueLine {\n    width: 100%;\n    height: 20px;\n    background-color: #293D6B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionBlock": "Footer_descriptionBlock__1d5p6",
	"blueLine": "Footer_blueLine__2CfFi"
};
export default ___CSS_LOADER_EXPORT___;
