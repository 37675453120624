import styles from './TypeformSolution.module.css'
import { Breadcumb } from "../../components";
import { NavLink, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { createWidget } from "@typeform/embed";
import ChallengesService from "../../services/ChallengesService";
import SolutionReview from "../SolutionReview/SolutionReview";

const TypeformSolution = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [result, setResult] = useState(null)
    const [stage, setStage] = useState('voting')
    const [responseId, setResponseId] = useState(null)
    const params = useParams()

    useEffect(() => {
        createWidget(params.formId, {
            container: document.getElementById('typeform'), onSubmit: async (event) => {
                try {
                    setIsLoading(true)
                    const response = await ChallengesService.registerSolution(params.formId, params.id, event.responseId)
                    setResult(response.data.json)
                    console.log(response.data.json)
                    setResponseId(response.data.json.id)
                    setStage('result')
                } catch (e) {
                    setError(e.message)
                } finally {
                    setIsLoading(false)
                }
            }
        })
    }, [])

    return (
        <>
            <Breadcumb links={[{
                title: 'Challenges',
                link: '/challenges'
            }, {
                title: 'Current challenge',
                link: `/challenges/${params.id}`
            }, {
                title: 'Challenge solution',
                link: `/challenges/${params.id}/solution`
            }]} />
            {
                error ?
                    <div className={styles.errorBlock}>
                        <p>An error occured on register solution, please, try again later</p>
                        <p>Error message: {error}</p>
                    </div> :
                    isLoading ?
                        <div className={styles.errorBlock}>
                            <p>Loading</p>
                        </div> :
                        stage === 'voting' ?
                            <section className={styles.mainSection} id={"typeform"} /> :
                            <SolutionReview result={result} responseId={responseId} />
            }
        </>
    )
}

export default TypeformSolution