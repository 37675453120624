// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpanText_spanText__1H_9J {\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 26px;\n}\n\n.SpanText_spanText__1H_9J b {\n    font-weight: 600;\n}", "",{"version":3,"sources":["webpack://src/components/SpanText/SpanText.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".spanText {\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 26px;\n}\n\n.spanText b {\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spanText": "SpanText_spanText__1H_9J"
};
export default ___CSS_LOADER_EXPORT___;
