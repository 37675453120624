import axios from "axios";
import $api from "../http";

export default class ChallengesService {
    static async createChallenge(responseId) {
        return $api.post('/challenges', { responseId })
    }

    static async getChallenges() {
        // return axios({
        //     method: 'GET',
        //     url: '/api/challenges'
        // })
        return $api.get('/challenges')
    }

    static async getLastChallenges(count) {
        return $api.get(`/challenges/last/${count}`)
    }

    static async getChallengeInfo(id) {
        return $api.get(`/challenges/${id}`)
    }

    static async registerDemand(formId, challengeId, responseId) {
        return $api.post('/demands/', { formId, challengeId, responseId })
    }

    static async registerSolution(formId, challengeId, responseId) {
        return $api.post('/solutions/', { formId, challengeId, responseId })
    }

    static async getSolutions(challengeId) {
        return $api.get(`/challenges/${challengeId}/solutions`)
    }
}