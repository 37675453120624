import React, { useState } from 'react'
import styled from 'styled-components'

// import FormUserEdit from './FormUserEdit'
// import { useNotification } from './NotificationProvider'
import PageHeader from './PageHeader'
import PageSection from './PageSection'
import { CanUser } from './CanUser'
import FormQR from './FormQR'

import {
  DataTable,
  DataRow,
  // DataRowHover,
  DataHeader,
  // DataCell,
  // AttributeTable,
  // AttributeRow,
} from './CommonStylesTables'

const DashboardButton = styled.div`
  width: 32%;
  min-width: 240px;
  height: 150px;
  margin-bottom: 30px;
  padding: 0 25px;
  font-size: calc(12px + 1.5vw);
  line-height: 150px;
  vertical-align: center;
  text-transform: uppercase;
  background: ${(props) => props.theme.primary_color};
  color: ${(props) => props.theme.text_light};
  box-shadow: ${(props) => props.theme.drop_shadow};
  text-align: center;

  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.background_primary};
    color: ${(props) => props.theme.text_color};
  }
`

// const EditCell = styled.td`
//   color: ${(props) => props.theme.primary_color};
//   box-shadow: none;
//   text-align: center;
//   font-size: 1.2em;
//   :hover {
//     cursor: pointer;
//   }
// `

function User(props) {
  // Accessing notification context
  // const setNotification = useNotification()

  // const [userEditModalIsOpen, setUserEditModalIsOpen] = useState(false)
  const [displayModalIsOpen, setDisplayModalIsOpen] = useState(false)

  // const closeUserEditModal = () => setUserEditModalIsOpen(false)
  const closeDisplayModal = () => setDisplayModalIsOpen(false)

  // const editUser = (email) => {
  //   setUserEditModalIsOpen(true)
  // }
  
  const presentInvite = () => {
    setDisplayModalIsOpen((o) => !o)

    props.sendRequest('INVITATIONS', 'CREATE_ACCOUNT_INVITATION', {})
  }

  return (
    <>
      <div id="profile">
        <PageHeader title={'Profile'} />
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Login</DataHeader>
                <DataHeader>Email</DataHeader>
                <DataHeader>Roles</DataHeader>
                <DataHeader>Password</DataHeader>
                <DataHeader>Reset ID</DataHeader>
                <DataHeader></DataHeader>
              </DataRow>
            </thead>
          </DataTable>
        </PageSection>
        <DashboardButton onClick={presentInvite}>
           Connect
        </DashboardButton>

        {/* <FormUserEdit
          userEditModalIsOpen={userEditModalIsOpen}
          closeUserEditModal={closeUserEditModal}
        /> */}
        <FormQR
        contactModalIsOpen={displayModalIsOpen}
        closeContactModal={closeDisplayModal}
        QRCodeURL={props.QRCodeURL}
        sendRequest={props.sendRequest}
        />
      </div>
    </>
  )
}

export default User
