// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderProfileLink_header__profilelink__3AphZ {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n\n    font-family: \"Poppins\", \"Roboto\", serif;\n }\n\n.HeaderProfileLink_header__avatar__hLu6q {\n     width: 32px;\n     height: 32px;\n     border-radius: 50px;\n     margin-right: 10px;\n\n     background: rgba(255, 255, 255, 0.2);\n }\n\n.HeaderProfileLink_header__link_combined__fFUd4 {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.HeaderProfileLink_header__link_combined__fFUd4 svg {\n    margin-left: 5px;\n}", "",{"version":3,"sources":["webpack://src/components/HeaderProfileLink/HeaderProfileLink.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;;IAEnB,uCAAuC;CAC1C;;AAED;KACK,WAAW;KACX,YAAY;KACZ,mBAAmB;KACnB,kBAAkB;;KAElB,oCAAoC;CACxC;;AAED;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".header__profilelink {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n\n    font-family: \"Poppins\", \"Roboto\", serif;\n }\n\n.header__avatar {\n     width: 32px;\n     height: 32px;\n     border-radius: 50px;\n     margin-right: 10px;\n\n     background: rgba(255, 255, 255, 0.2);\n }\n\n.header__link_combined {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.header__link_combined svg {\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header__profilelink": "HeaderProfileLink_header__profilelink__3AphZ",
	"header__avatar": "HeaderProfileLink_header__avatar__hLu6q",
	"header__link_combined": "HeaderProfileLink_header__link_combined__fFUd4"
};
export default ___CSS_LOADER_EXPORT___;
