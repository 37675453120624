import styles from './SignUpForUpdates.module.css'
import {Button} from "../../index";

const SignUpForUpdates = () => {
    return (
        <section className={styles.modalContent}>
            <span className={styles.title}>Sign Up for UGIH Updates</span>
            <span className={styles.subtitle}>Enter your email to be included in the upcoming Newsletter and access to demo testing</span>
            <input type="text" className={styles.input} placeholder={"Email address"}/>
            <Button caption={"Sign Up for Updates"} type={"basic"} />
        </section>
    )
}

export default SignUpForUpdates