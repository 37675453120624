import styles from './SolutionCardExplore.module.css'
import { useHistory } from "react-router-dom";
import { SpanText } from "../index";

const SolutionCardExplore = ({ results }) => {
    const history = useHistory()

    const toChallengeDetails = () => {
        history.push(`/solutionform/${results.ChallengeId}/${results.id}`);
    }

    const getLines = (results) => {
        const hiden = ['id', 'ChallengeId', 'ChallengeName', 'Created', 'Modified', 'Owner', 'isDemo']
        const tmpLines = []
        for (const key in results) {
            if (!hiden.includes(key)) {
                tmpLines.push(
                    <p className={styles.info}>{key} <span className={styles.greenText}>{results[key]}</span>
                    </p>
                )
            }
        }
        return tmpLines
    }

    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.nameAndDate}>
                    <span className={styles.challengeName}>{results["What is your Solution's name?"]}</span>
                    <span className={styles.date}>Last updated {new Date(results.Modified).toLocaleDateString()}</span>
                </div>
                <button className={styles.button} onClick={toChallengeDetails}>Go to solution</button>
            </div>
            <hr className={styles.hr} />
            <SpanText>{results["Describe your solution in a few sentences"]}</SpanText>
            <p className={styles.info}>Submitted by:  <span className={styles.greenText}>{results["Please provide the name of the entity you represent"]}</span></p>
            <p className={styles.info}>Submission type:  <span className={styles.greenText}>Solution</span></p>
            {/*{getLines(results)}*/}
        </div>
    )
}

export default SolutionCardExplore