import styles from './LoginWithDemoAccount.module.css'
import { Button } from "../../index";
import { useState } from 'react';

const LoginWithDemoAccount = ({ demoLogin }) => {
    const [value, setValue] = useState('')
    return (
        <section className={styles.modalContent}>
            <span className={styles.title}>Log in with a demo account</span>
            <span className={styles.subtitle}>Please enter your email to be granted demo access.</span>
            <input type="text" className={styles.input} placeholder={"Email address"} value={value} onChange={(e) => setValue(e.target.value)} />
            <Button caption={"Log in with demo account"} type={"basic"} onClick={() => demoLogin(value)} />
        </section>
    )
}

export default LoginWithDemoAccount