import styles from './Navigation.module.css'
import { NavLink } from "react-router-dom";
import GlobalState from "../../context/GlobalState";
import { useContext } from 'react'

const Navigation = () => {
    const [state] = useContext(GlobalState);

    return (
        <nav>
            <ul className={styles.navList}>
                <li>
                    <NavLink to={"/"} exact activeClassName={styles.linkActive}>Home</NavLink>
                </li>
                <li>
                    <NavLink to={"/challenges"} activeClassName={styles.linkActive}>
                        Challenges
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/explore"} exact activeClassName={styles.linkActive}>
                        Explore
                    </NavLink>
                </li>
                {
                    !state.isDemo ?
                        <li>
                            <NavLink to={"/credentials"} exact activeClassName={styles.linkActive}>Credentials</NavLink>
                        </li> :
                        null
                }

            </ul>
        </nav>
    )
}

export default Navigation