import styles from './WhatAreCredentials.module.css'
import lissi from './lissi.png'
import trinsic from './trinsic.png'
import satus from './satus.png'

const WhatAreCredentials = () => {
    return (
        <section className={styles.modalContent}>
            <span className={styles.title}>WHAT ARE CREDENTAILS</span>
            <span className={styles.subtitle}>Understanding Verifiable Credentials</span>
            <p>The word <b>credential</b> usually refers to any document, card, certificate, etc.that contains information about something.We use credentials every day, including driver’s licenses, passports, diplomas, insurance cards, medical test results, employee badges, and more.</p>
            <p>All of these examples are <b>physical credentials</b> that you can hold in your hand.However, a credential becomes a <b>verifiable credential</b> once it is in a <b>standard, digital format which you keep in your phone or the cloud.</b></p>
            <p>Therefore:</p>
            <p><b>Verifiable credentials are standardized, digital certificates that make it easy to share information online in a private and secure way.</b></p>
            <p>We say “standardized” because the way verifiable credentials should be built has been <b>standardized by the internet’s leading standards body.</b> That means there is a correct way to create a verifiable credential, so that it is <b>interoperable through different systems.</b></p>
            <p>Source: <i>https://trinsic.id/what-are-verifiable-credentials/</i></p>
            <span className={styles.subtitle}>How to use Verifiable Credentials in this Demo</span>
            <p>In order to use Verifiable Credentials in this demo you should download any of the compatible mobile wallets.</p>
            <p>After installation you will be able to accept a Demo Credential issued by the UGIH Prototype staff.This credential will verify your identity and your connection to the entity you represent.</p>
            <p>By scanning a QR code to log in, you will be sharing your credential’s information with the platform in order to verify who you are and gain access.</p>
            <p>To get started, download any of the following apps in the App Store or Play Store:  </p>
            <div className={styles.images}>
             <img src={trinsic} />
            <img src={satus} />
            <img src={lissi} />
</ div>
        </section>
    )
}

export default WhatAreCredentials










