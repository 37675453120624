import logo from './logo 1.png'
import styles from './Logo.module.css'

const Logo = () => {
    return (
        <div className={styles.logo}>
            <img src={logo} alt="" />
            <div>
                <span><b>United Nations</b> Climate Change</span>
                <span>Global Innovation Hub</span>
                <span>
                    DEMO SITE
                </span>
            </div>
        </div>
    )
}

export default Logo