import styles from './ConnectionCard.module.css'

const ConnectionCard = () => {
    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.nameAndDate}>
                    <span className={styles.challengeName}>UGIH Platform</span>
                    <span className={styles.date}>DATE CONNECTED  June 2019</span>
                </div>
                <button className={styles.button}>See connection details</button>
            </div>
            <hr className={styles.hr} />
            <span className={styles.desc}>This connection with the UGIH Platform allows you to transfer information securely and in a trusted manner.</span>
            <p className={styles.info}>STATUS: <span className={styles.greenText}>TRUSTED</span></p>
        </div>
    )
}

export default ConnectionCard