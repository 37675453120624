// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navigation_navList__O4n9h {\n    display: flex;\n    flex-direction: row;\n    list-style: none;\n    background: linear-gradient(0deg, #5191CE, #5191CE), #89A8E2;\n    padding-left: 65px;\n    padding-right: 65px;\n}\n\n.Navigation_navList__O4n9h li {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.Navigation_navList__O4n9h li a {\n    padding: 10px 20px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 21px;\n    text-transform: uppercase;\n    color: #FFFFFF;\n}\n\n.Navigation_linkActive__1ybUC {\n    border-bottom: 4px solid #FFFFFF;\n    padding-bottom: 6px !important;\n}\n\n.Navigation_navList__O4n9h li a {\n    text-decoration: none;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/Navigation/Navigation.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,4DAA4D;IAC5D,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,gCAAgC;IAChC,8BAA8B;AAClC;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf","sourcesContent":[".navList {\n    display: flex;\n    flex-direction: row;\n    list-style: none;\n    background: linear-gradient(0deg, #5191CE, #5191CE), #89A8E2;\n    padding-left: 65px;\n    padding-right: 65px;\n}\n\n.navList li {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.navList li a {\n    padding: 10px 20px;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 14px;\n    line-height: 21px;\n    text-transform: uppercase;\n    color: #FFFFFF;\n}\n\n.linkActive {\n    border-bottom: 4px solid #FFFFFF;\n    padding-bottom: 6px !important;\n}\n\n.navList li a {\n    text-decoration: none;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navList": "Navigation_navList__O4n9h",
	"linkActive": "Navigation_linkActive__1ybUC"
};
export default ___CSS_LOADER_EXPORT___;
