import styles from './TypeformCreateChallenge.module.css'
import { Breadcumb } from "../../components";
import { Widget } from "@typeform/embed-react";
import { useState } from "react";
import ChallengesService from "../../services/ChallengesService";

const TypeformCreateChallenge = () => {
    const [isFormSumbitted, setIsFormSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const registerChallenge = async (responseId) => {
        setIsLoading(true)
        const data = await ChallengesService.createChallenge(responseId)
        console.log(data)
        setIsFormSubmitted(true)
        setIsLoading(false)
    }

    return (
        <>
            <Breadcumb links={[{
                title: 'Challenges',
                link: '/challenges'
            }, {
                title: 'Create challenge',
                link: '/challenges/create'
            }]} />
            <section className={styles.mainSection}>
                {isLoading ?
                    <p>Loading</p> :
                    isFormSumbitted ?
                        <p>Challenge created</p> :
                        <Widget id={process.env.REACT_APP_TYPEFORM_CREATE_CHALLENGE_FORM_ID} onSubmit={(e) => {
                            registerChallenge(e.responseId).then()
                        }}
                            style={{
                                width: '100%'
                            }}
                        />
                }
            </section>
        </>
    )
}

export default TypeformCreateChallenge