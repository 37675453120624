import styles from './CredentialsHome.module.css'
import logo from '../Logo/logo 1.png'
import {Button} from "../index";

const CredentialsHome = () => {
    return (
        <div className={styles.content}>
            <img src={logo} alt={""} />
            <p className={styles.title}>
                <span>GLOBAL INNOVATION HUB</span>
                <span>DIGITAL TRUST NETWORK</span>
            </p>
            <p className={styles.spanText}>Lorem ipsum. The Communication and Engagement division serves multiple cross-cutting objectives like communicating authoritative, relevant and timely information to Parties, non-Party stakeholders and the public regarding the secretariat’s process and action on climate change, with emphasis on proactively building support among all stakeholders so as to facilitate climate action and increase ambition. It ensures that the communications work and engagement activities of the secretariat are driven by the outcomes of all processes and bodies.</p>
            <Button type={"basicSecondary"} caption={"SCAN QR CODE"} />
        </div>
    )
}

export default CredentialsHome