import {
    Button,
    Footer,
    H2,
    Header,
    LoginWithCredentials, LoginWithDemoAccount,
    Modal, SignUpForUpdates,
    SpanText,
    WhatAreCreditionals,
    YouTubeVideo
} from "../../components";
import styles from './Landing.module.css'
import img from './src.jpg'
import { useContext, useState } from "react";
import GlobalState from "../../context/GlobalState";
import { propTypes } from "qrcode.react";

const Landing = (props) => {
    if (!props.QRCodeURL) {
        props.sendRequest('INVITATIONS', 'CREATE_SINGLE_USE', {})
    }

    const [isModalShow, setIsModalShow] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const showModalWhatAreCreditionals = () => {
        setModalContent(<WhatAreCreditionals />)
        setIsModalShow(true);
    }

    const showModalLoginWithCredentials = () => {
        setModalContent(<LoginWithCredentials QRCodeURL={props.QRCodeURL} />)
        setIsModalShow(true);
    }

    const showModalLoginWithDemoAccount = () => {
        setModalContent(<LoginWithDemoAccount demoLogin={props.demoLogin} />)
        setIsModalShow(true);
    }

    const showModalSignUpForUpdates = () => {
        setModalContent(<SignUpForUpdates />)
        setIsModalShow(true);
    }

    return (
        <>
            <Header isAuthenticated={false} />
            <main className={styles.main}>
                <section className={styles.frame1}>
                    <img src={img} alt="" />
                    <div>
                        <H2>Innovative Bridging of Climate Solutions Supply & Demand</H2>
                        <div className={styles.textInfoBlock}>
                            <SpanText>
                                The Innovation Hub was launched to promote transformative innovations for a low-emission and climate-resilient future.
                            </SpanText>
                            <SpanText>
                                The Hub expands the global innovation space by facilitating solutions that advance the climate-related Sustainable Development Goals (SDGs) and that address core human needs for food, shelter, mobility, and access via alternative value chains that are aligned with those SDGs.
                                <br />
                                <b>This platform is a demo environment to explore how the UGIH full-fledged platform should be built</b>
                            </SpanText>
                        </div>
                        <span className={styles.demoInfo}>This demo is by invitation only by UNFCCC</span>
                        <Button caption={"Log In with credential"} subtitle={"what are credentials?"} onClick={showModalLoginWithCredentials} subtitleClick={showModalWhatAreCreditionals} />
                        <Button type={"secondary"} caption={"Log In with demo access"} onClick={showModalLoginWithDemoAccount} />
                        <Button type={"info"} caption={"Sign Up for updates"} onClick={showModalSignUpForUpdates} />
                    </div>
                </section>
                <section className={styles.frame2}>
                    <H2>Solution-Oriented Approaches vs. Problem-Oriented Approaches</H2>
                    <SpanText>The Innovation Hub complements the current approach to innovation for climate solutions —an approach that has tended to be incremental, sector-based and problem-oriented—with a transformative, need-based and solution-oriented one.</SpanText>
                    <YouTubeVideo link={"https://www.youtube.com/embed/ntkkANYgBR8"} />
                </section>
                <section className={styles.frame3}>
                    <details>
                        <summary>FAQ</summary>
                        <p>FAQ description</p>
                    </details>
                </section>
                <Modal onClose={() => setIsModalShow(false)} title={"Test"} show={isModalShow}>
                    {modalContent}
                </Modal>
            </main>
            <Footer />
        </>
    )
}

export default Landing