import styles from './Solutions.module.css'
import { H2 } from "../../components";
import { useEffect, useState } from "react";
import DemandsService from "../../services/DemandsService";
import SolutionCardExplore from "../../components/SolutionCardExplore/SolutionCardExplore";
import SolutionsService from "../../services/SolutionsService";

const Solutions = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [solutions, setSolutions] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const data = await SolutionsService.getSolutions()
                setSolutions(data.data)
            } catch (e) {
                setError(e.message)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData().then()
    }, [])

    return (
        <section className={styles.mainSection}>
            <H2>All Solutions</H2>
            <div className={styles.cardsLine}>
                {isLoading ?
                    <p>Loading</p> :
                    error ?
                        <>
                            <p>An error occured on loading demands, please, try again later</p>
                            <p>Error message: ${error}</p>
                        </> :
                        null
                }
                {solutions ?
                    solutions.json.map(solution => (
                        <SolutionCardExplore key={solution.id} results={solution} />
                    )) :
                    null
                }
            </div>
        </section>
    )
}

export default Solutions