import $api from "../http";

export default class DemandsService {
    static async getDemands() {
        return $api.get('/demands')
    }

    static async getLastDemands(count) {
        return $api.get(`/demands/last/${count}`)
    }

    static async updateDemand(responseId, data) {
        return $api.put(`/demands/${responseId}`, { data })
    }

    static async updateSolution(responseId, data) {
        return $api.put(`/solutions/${responseId}`, { data })
    }

    static async getDemandById(demandId) {
        return $api.get(`/demands/${demandId}`)
    }
}