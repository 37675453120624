import styles from './Demands.module.css'
import { DemandCard, H2 } from "../../components";
import { useEffect, useState } from "react";
import DemandsService from "../../services/DemandsService";

const Demands = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [demands, setDemands] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const data = await DemandsService.getDemands()
                setDemands(data.data)
            } catch (e) {
                setError(e.message)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData().then()
    }, [])

    return (
        <section className={styles.mainSection}>
            <H2>All Demands</H2>
            <div className={styles.cardsLine}>
                {isLoading ?
                    <p>Loading</p> :
                    error ?
                        <>
                            <p>An error occured on loading demands, please, try again later</p>
                            <p>Error message: ${error}</p>
                        </> :
                        null
                }
                {demands ?
                    demands.json.map(demand => (
                        <DemandCard key={demand.id} results={demand} />
                    )) :
                    null
                }
            </div>
        </section>
    )
}

export default Demands