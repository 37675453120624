// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuestionCard_card__3EbUk {\n    display: flex;\n    flex-direction: column;\n    padding: 20px 25px;\n    border: 1px solid #E6E7E8;\n    border-radius: 4px;\n}\n\n.QuestionCard_card__3EbUk span:first-child {\n    font-style: normal;\n    font-weight: 500;\n    font-size: 13px;\n    line-height: 19px;\n    color: #272727;\n}\n\n.QuestionCard_card__3EbUk span:last-child {\n    font-style: normal;\n    font-weight: normal;\n    font-size: 10px;\n    line-height: 15px;\n    margin-top: 2px;\n}", "",{"version":3,"sources":["webpack://src/components/QuestionCard/QuestionCard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".card {\n    display: flex;\n    flex-direction: column;\n    padding: 20px 25px;\n    border: 1px solid #E6E7E8;\n    border-radius: 4px;\n}\n\n.card span:first-child {\n    font-style: normal;\n    font-weight: 500;\n    font-size: 13px;\n    line-height: 19px;\n    color: #272727;\n}\n\n.card span:last-child {\n    font-style: normal;\n    font-weight: normal;\n    font-size: 10px;\n    line-height: 15px;\n    margin-top: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "QuestionCard_card__3EbUk"
};
export default ___CSS_LOADER_EXPORT___;
