// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Explore_mainSection__3PiId {\n    padding: 85px 100px;\n}\n\n.Explore_createChallengeLine__3xbaR {\n    display: flex;\n    flex-direction: row-reverse;\n    width: 100%;\n}\n\n.Explore_cardsLine__nVU8e {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    /*justify-content: space-between;*/\n    align-items: center;\n    width: 100%;\n    margin-top: 25px;\n    grid-column-gap: 20px;\n    column-gap: 20px;\n    grid-row-gap: 20px;\n    row-gap: 20px;\n    justify-content: flex-start;\n}\n\n.Explore_cardsLine__nVU8e > div {\n    margin-bottom: 25px;\n}\n\n.Explore_mainSection__3PiId h2:not(:first-of-type) {\n    margin-top: 50px;\n}", "",{"version":3,"sources":["webpack://src/pages/Explore/Explore.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kCAAkC;IAClC,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,qBAAgB;IAAhB,gBAAgB;IAChB,kBAAa;IAAb,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".mainSection {\n    padding: 85px 100px;\n}\n\n.createChallengeLine {\n    display: flex;\n    flex-direction: row-reverse;\n    width: 100%;\n}\n\n.cardsLine {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    /*justify-content: space-between;*/\n    align-items: center;\n    width: 100%;\n    margin-top: 25px;\n    column-gap: 20px;\n    row-gap: 20px;\n    justify-content: flex-start;\n}\n\n.cardsLine > div {\n    margin-bottom: 25px;\n}\n\n.mainSection h2:not(:first-of-type) {\n    margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSection": "Explore_mainSection__3PiId",
	"createChallengeLine": "Explore_createChallengeLine__3xbaR",
	"cardsLine": "Explore_cardsLine__nVU8e"
};
export default ___CSS_LOADER_EXPORT___;
