import styles from './ChallengeCard.module.css'
import { NavLink, useHistory } from "react-router-dom";

const ChallengeCard = ({ id, name, desc, coreNeed, region, lastUpdate, type, isActive, demandFormId, solutionFormId }) => {
    const history = useHistory()

    const toChallengeDetails = () => {
        history.push(`/challenges/${id}`);
    }

    const toReview = () => {
        console.log(`/challenges/review/${id}`)
        history.push(`/challenges/review/${id}`)
    }

    const getDemandFormId = (type) => {
        switch (type) {
            case "Access":
                return process.env.REACT_APP_TYPEFORM_ACCESS_DEMAND_FORM_ID
            case "Shelter":
                return process.env.REACT_APP_TYPEFORM_SHELTER_DEMAND_FORM_ID
            default:
                return process.env.REACT_APP_TYPEFORM_ACCESS_DEMAND_FORM_ID
        }
    }

    const getSoutionFormId = (type) => {
        switch (type) {
            case "Access":
                return process.env.REACT_APP_TYPEFORM_ACCESS_SOLUTION_FORM_ID
            case "Shelter":
                return process.env.REACT_APP_TYPEFORM_SHELTER_SOLUTION_FORM_ID
            default:
                return process.env.REACT_APP_TYPEFORM_ACCESS_SOLUTION_FORM_ID
        }
    }

    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.nameAndDate}>
                    <span className={styles.challengeName}>{name}</span>
                    <span className={styles.date}>Last updated {new Date(lastUpdate).toLocaleDateString()}</span>
                </div>
                {
                    isActive ?
                        <button className={styles.button} onClick={toChallengeDetails}>Go to challenge</button> :
                        <button className={styles.button} onClick={toReview}>Review challenge</button>
                }

            </div>
            <hr className={styles.hr} />
            <span className={styles.desc}>{desc}</span>
            <p className={styles.info}>Core need: <span className={styles.greenText}>{coreNeed}</span></p>
            <p className={styles.info}>Region: <span className={styles.greenText}>{region}</span></p>
            {
                isActive ?
                    <div className={styles.linksLine}>
                        <NavLink to={`/challenges/${id}/demand/${demandFormId}`}>Complete Demand Submission ></NavLink>
                        <NavLink to={`/challenges/${id}/solution/${solutionFormId}`}>Complete Solution Submission ></NavLink>
                    </div> :
                    null
            }
        </div>
    )
}

export default ChallengeCard