import styles from './TypeformDemand.module.css'
import { Breadcumb } from "../../components";
import { createWidget } from '@typeform/embed'
import { useEffect, useState } from "react";
import { DemandReview } from "../index";
import { useParams } from "react-router-dom";
import ChallengesService from "../../services/ChallengesService";

const TypeformDemand = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [result, setResult] = useState(null)
    const [stage, setStage] = useState('voting')
    const [responseId, setResponseId] = useState(null)
    const params = useParams()

    useEffect(() => {
        createWidget(params.formId, {
            container: document.getElementById('typeform'), onSubmit: async (event) => {
                try {
                    setIsLoading(true)
                    const response = await ChallengesService.registerDemand(params.formId, params.id, event.responseId)
                    setResult(response.data.json)
                    setResponseId(response.data.json.id)
                    setStage('result')
                } catch (e) {
                    setError(e.message)
                } finally {
                    setIsLoading(false)
                }
            }
        })
    }, [])

    return (
        <>
            <Breadcumb links={[{
                title: 'Challenges',
                link: '/challenges'
            }, {
                title: 'Current challenge',
                link: `/challenges/${params.id}`
            }, {
                title: 'Challenge demand',
                link: `/challenges/${params.id}/demand`
            }]} />
            {
                error ?
                    <>
                        <p>An error occured on register demand, please, try again later</p>
                        <p>Error message: ${error}</p>
                    </> :
                    isLoading ?
                        <p>Loading</p> :
                        stage === 'voting' ?
                            <section className={styles.mainSection} id={"typeform"} /> :
                            <DemandReview result={result} responseId={responseId} />
            }
        </>
    )
}

export default TypeformDemand