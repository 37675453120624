import styles from './CredentialCard.module.css'

const CredentialCard = () => {
    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.nameAndDate}>
                    <span className={styles.challengeName}>City Verification</span>
                    <span className={styles.date}>DATE shared  June 2019</span>
                </div>
                <button className={styles.button}>See credential details</button>
            </div>
            <hr className={styles.hr} />
            <span className={styles.desc}>This credential verifies that you are a member of your city’s administration</span>
            <p className={styles.info}>ISSUED by: UGIH</p>
            <p className={styles.info}>NAME: JANE SMITH</p>
            <p className={styles.info}>CITY: JAKARTA</p>
            <p className={styles.info}>ROLE: CITY MANAGER</p>
            <p className={styles.info}>STATUS: <span className={styles.greenText}>VERIFIED</span></p>
        </div>
    )
}

export default CredentialCard