import styles from './QuestionCard.module.css'

const QuestionCard = ({question, answer}) => {
    return (
        <div className={styles.card}>
            <span>{question}</span>
            <span>{answer}</span>
        </div>
    )
}

export default QuestionCard