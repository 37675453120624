import styles from './HeaderNav.module.css'

const HeaderNav = () => {
    return (
        <nav className={styles.header__nav}>
            <ul className={styles.header__list}>
                <li>About</li>
                <li>Challenges</li>
            </ul>
        </nav>
    )
}

export default HeaderNav