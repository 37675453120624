import styles from './Footer.module.css'

const Footer = () => {
    return (
        <footer>
            <div className={styles.descriptionBlock}>
                <p>
                    <b>This is a demonstration site, not a fully functional and operational UNFCCC platform</b>
                    <span>
                        The site you are using was built by the <a href="http://www.openearth.org/">Open Earth Foundation</a> in collaboration with the UNFCCC in order to test features and functions to consider for a full development of the Global Innovation Hub platform.We appreciate your patience if there are any glitches in the user experience.To provide feedback on the features or report any issues please email ux @openearth.org
                    </span>
                </p>
                <p>
                    <b>Copyright, Terms of Use & Privacy Policy</b>
                    <span>Please visit <a href="www. unfccc.int/terms-of-use">UNFCCC Tems of Use</a> for a description of Copyrights and Terms of Use of this site.If you do not accept the Copyright and Terms of Use, do not use this website. </span>
                </p>
                <p>
                    <b>Open Source</b>
                    <span>The UGIH is conceived as a Digital Public Good following <a href="https://www.un.org/techenvoy/content/digital-public-goods">UN recommendations</a>. As such, the platform will be published under open source licenses.</span>
                </p>
            </div>
            <div className={styles.blueLine} />
        </footer>
    )
}

export default Footer
