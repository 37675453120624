import $api from "../http";

export default class SolutionsService {
    static async getSolutions() {
        return $api.get('/solutions')
    }

    static async getLastSolutions(count) {
        return $api.get(`/solutions/last/${count}`)
    }

    static async updateSolution(responseId, data) {
        return $api.put(`/solutions/${responseId}`, { data })
    }

    static async getSolutionById(id) {
        return $api.get(`/solutions/${id}`)
    }
}