const YouTubeVideo = ({link}) => {
    return (
        <iframe src={link}
                style={{width: 865, height: 470}}
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
    )
}

export default YouTubeVideo