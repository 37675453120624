import styles from './CredentialsConnections.module.css'
import {ConnectionCard, H2, SpanText} from "../index";

const CredentialsConnections = () => {
    return (
        <div className={styles.content}>
            <H2>My Connections</H2>
            <SpanText>Lorem ipsum. The Communication and Engagement division serves multiple cross-cutting objectives like communicating authoritative, relevant and timely information to Parties, non-Party stakeholders and the public regarding the secretariat’s process and action on climate change, with emphasis on proactively building support among all stakeholders so as to facilitate climate action and increase ambition. It ensures that the communications work and engagement activities of the secretariat are driven by the outcomes of all processes and bodies.</SpanText>
            <div className={styles.cards}>
                <ConnectionCard />
                <ConnectionCard />
                <ConnectionCard />
                <ConnectionCard />
            </div>
        </div>
    )
}

export default CredentialsConnections
