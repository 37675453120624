import styles from './Breadcumb.module.css'
import { NavLink } from "react-router-dom";

const Breadcumb = ({ links }) => {
    return (
        <div className={styles.breadcumb}>
            {links.map((item, index) => {
                if (index !== links.length - 1) {
                    return <>
                        <NavLink to={item.link}>{item.title}</NavLink>
                        <span>&#10095;</span>
                    </>
                }
                return <NavLink to={item.link}>{item.title}</NavLink>
            })}
        </div>
    )
}

export default Breadcumb