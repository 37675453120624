import styles from './SideNav.module.css'
import logo from './logo.png';
import {Button} from "../index";

const SideNav = ({pages, setActivePage}) => {
    return (
        <div className={styles.sideNav}>
            <div>
                <div className={styles.logo}>
                    <img src={logo} alt={""}/>
                    <p>
                        <span className={styles.title}>Global Innovation Hub</span>
                        <span className={styles.subtitle}>DIGITAL TRUST NETWORK</span>
                        <span className={styles.userName}>CITY OFFICIAL</span>
                    </p>
                    <Button type={"link"} caption={"Log Out >"} />
                </div>
                <ul>
                    {pages.map(page => {
                        return <li className={`${page.isActive ? styles.active: "" }`} onClick={() => setActivePage(page.name)}>{page.name}</li>
                    })}
                </ul>
            </div>
            <Button type={"link"} caption={"ABOUT"} />
        </div>
    )
}

export default SideNav