import styles from './Explore.module.css'
import { Button, ChallengeCard, DemandCard, H2 } from "../../components";
import { useEffect, useState } from "react";
import ChallengesService from "../../services/ChallengesService";
import DemandsService from "../../services/DemandsService";
import SolutionsService from "../../services/SolutionsService";
import SolutionCardExplore from "../../components/SolutionCardExplore/SolutionCardExplore";
import { useHistory } from "react-router-dom";

const Explore = () => {
    const [error, setError] = useState(null)
    const [isChallengesLoading, setIsChallengesLoading] = useState(null)
    const [isDemandLoading, setIsDemandLoading] = useState(null)
    const [isSolutionsLoading, setIsSolutionsLoading] = useState(null)

    const [activeChallenges, setActiveChallenges] = useState(null)
    const [inactiveChallenges, setInactiveChallenges] = useState(null)
    const [demands, setDemands] = useState(null)
    const [solutions, setSolutuions] = useState(null)

    const history = useHistory()

    useEffect(() => {
        const fetchChallenges = async () => {
            try {
                setIsChallengesLoading(true)
                const response = await ChallengesService.getChallenges()
                setActiveChallenges(response.data.json.filter(item => item.isActive))
                setInactiveChallenges(response.data.json.filter(item => !item.isActive))
            } catch (e) {
                setError(e.message)
            } finally {
                setIsChallengesLoading(false)
            }
        }
        fetchChallenges().then()
    }, [])

    useEffect(() => {
        const fetchDemands = async () => {
            try {
                setIsDemandLoading(true)
                const response = await DemandsService.getLastDemands(2)
                setDemands(response.data)
                console.log(response.data)
            } catch (e) {
                setError(e.message)
            } finally {
                setIsDemandLoading(false)
            }
        }
        fetchDemands().then()
    }, [])

    useEffect(() => {
        const fetchSolutions = async () => {
            try {
                setIsSolutionsLoading(true)
                const response = await SolutionsService.getLastSolutions(2)
                setSolutuions(response.data)
            } catch (e) {
                setError(e.message)
            } finally {
                setIsSolutionsLoading(false)
            }
        }
        fetchSolutions().then()
    }, [])

    return (
        <section className={styles.mainSection}>
            {error ?
                <p>An error occured on loading data. Please, try again later</p> :
                null
            }
            <H2>Active Challenges</H2>
            <div className={styles.cardsLine}>
                {isChallengesLoading ?
                    <p>Loading</p> :
                    <>
                        {activeChallenges ?
                            activeChallenges.map(item => (
                                <ChallengeCard key={item.id} id={item.id} coreNeed={item['What core need does this Challenge address?']}
                                    desc={item["Describe your challenge's goals in a few sentences"]} name={item["What is the Challenge's name?"]} lastUpdate={item.Modified} type={item["What core need does this Challenge address?"]}
                                    region={item["What region does this challenge address?"]} isActive={true} demandFormId={item.demandFormId} solutionFormId={item.solutionFormId} />
                            )) :
                            null
                        }
                    </>
                }
            </div>
            <H2>Inactive Challenges</H2>
            <div className={styles.cardsLine}>
                {isChallengesLoading ?
                    <p>Loading</p> :
                    <>
                        {inactiveChallenges ?
                            inactiveChallenges.map(item => (
                                <ChallengeCard key={item.id} id={item.id} coreNeed={item['What core need does this Challenge address?']}
                                    desc={item["Describe your challenge's goals in a few sentences"]} name={item["What is the Challenge's name?"]} lastUpdate={item.Modified} type={item["What core need does this Challenge address?"]}
                                    region={item["What region does this challenge address?"]} isActive={false} />
                            )) :
                            null
                        }
                    </>
                }
            </div>
            <H2>All Demand Submissions</H2>
            <div className={styles.cardsLine}>
                {isDemandLoading ?
                    <p>Loading</p> :
                    <>
                        {demands ?
                            demands.json.map(item => (
                                <DemandCard key={item.id} results={item} />
                            )) :
                            null
                        }
                    </>
                }
            </div>
            <Button type={"link"} caption={"View more Demand Submissions >"} onClick={() => history.push('/demands')} />
            <H2>All Solution Submissions</H2>
            <div className={styles.cardsLine}>
                {isSolutionsLoading ?
                    <p>Loading</p> :
                    <>
                        {isSolutionsLoading ?
                            <p>Loading</p> :
                            <>
                                {solutions ?
                                    solutions.json.map(item => (
                                        <SolutionCardExplore key={item.id} results={item} />
                                    )) :
                                    null
                                }
                            </>
                        }
                    </>
                }
            </div>
            <Button type={"link"} caption={"View more Solution Submissions >"} onClick={() => history.push('/solutions')} />
        </section>
    )
}

export default Explore