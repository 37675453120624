import styles from './SolutionCard.module.css'
import { Button } from "../index";
import { useHistory } from "react-router-dom";

const SolutionCard = ({ solution }) => {
    const history = useHistory()

    return (
        <div className={styles.card}>
            <img src={""} alt="" />
            <div>
                <span>{solution["What is your Solution's name?"]}</span>
                <span>{solution["Describe your solution in a few sentences"]}</span>
                <Button type={"basic"} caption={"Explore solution"} onClick={() => history.push(`/solutionform/${solution.ChallengeId}/${solution.id}`)} />
            </div>
        </div>
    )
}

export default SolutionCard