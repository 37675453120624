import styles from './SolutionReview.module.css'
import { Button, QuestionCard } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ChallengesService from "../../services/ChallengesService";
import html2pdf from "html2pdf.js/src";
import DemandsService from "../../services/DemandsService";
import SolutionsService from "../../services/SolutionsService";

const SolutionReview = ({ result, responseId }) => {
    const [formType, setFormType] = useState('explore')
    const [challenge, setChallenge] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [results, setResults] = useState(result)
    const [form, setForm] = useState(null)
    const [questionCards, setQuestionCards] = useState(null)
    const [editableCards, setEditableCards] = useState(null)

    const params = useParams()
    const history = useHistory()

    const toggleFormType = () => {
        setFormType(formType === 'explore' ? 'edit' : 'explore')
    }

    const saveChanges = async () => {
        setIsLoading(true)
        const changes = await SolutionsService.updateSolution(responseId, form)
        setResults(changes.data.json)
        setFormType('explore')
        window.alert('Solution updated')
        setIsLoading(false)
    }

    const savePdf = () => {
        html2pdf().from(document.getElementById('wrapper')).save();
    }

    const updateUserForm = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    useEffect(() => {
        const hiden = ['id', 'ChallengeId', 'ChallengeName', 'Created', 'Modified', 'Owner', 'isDemo']
        const userForm = {}
        const tmpCards = []
        for (const key in results) {
            if (!hiden.includes(key)) {
                userForm[key] = results[key]
                tmpCards.push(<QuestionCard question={key} answer={results[key]} />)
            }
        }
        setForm(userForm)
        setQuestionCards(tmpCards)
        setEditableCards(true)
    }, [results])

    const createEditableCards = (form) => {
        const hiden = ['id', 'ChallengeId', 'ChallengeName', 'Created', 'Modified', 'Owner', 'isDemo']
        const tmpEditableCards = []
        for (const key in results) {
            if (!hiden.includes(key)) {
                tmpEditableCards.push(
                    <label>
                        <span>{key}</span>
                        <input value={form[key]} onChange={(e) => updateUserForm(key, e.target.value)} disabled={isLoading} />
                    </label>
                )
            }
        }
        return tmpEditableCards
    }

    useEffect(() => {
        const fetchChallenge = async () => {
            const response = await ChallengesService.getChallengeInfo(params.id)
            setChallenge(response.data)
        }
        fetchChallenge().then()
    }, [])

    return (
        <div className={styles.mainWrap}>
            <div className={styles.wrapper} id={"wrapper"}>
                <div className={styles.wrapperHeader}>
                    <div className={styles.textBlock}>
                        <span>Solution Submission: {challenge ? challenge.json.Name : null}</span>
                        <span>Last updated {challenge ? new Date(challenge.json.Modified).toLocaleDateString() : null}</span>
                    </div>
                    {
                        results && formType === 'explore' ?
                            <Button type={"link"} caption={"Edit responses"} onClick={toggleFormType} /> :
                            null
                    }
                    {
                        results && formType === 'edit' ?
                            <Button type={"link"} caption={"Cancel"} onClick={toggleFormType} /> :
                            null
                    }
                </div>
                <hr />
                {
                    formType === 'explore' ?
                        <div className={styles.cards}>
                            {
                                questionCards ? questionCards : null
                            }
                        </div> :
                        <div className={styles.editForm}>
                            {
                                editableCards ? createEditableCards(form) : null
                            }
                            <Button type={"basicSecondary"} caption={"Save changes"} onClick={saveChanges} disabled={isLoading} />
                        </div>
                }

            </div>
            <div className={styles.buttons}>
                <Button type={"basicSecondary"} caption={"Download Response PDF"} onClick={savePdf} />
            </div>
        </div>
    )
}

export default SolutionReview