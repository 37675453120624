import styles from './Header.module.css';
import { HeaderNav, Logo, HeaderProfileLink, HeaderButton } from "../index";
import { useContext } from "react";
import GlobalState from "../../context/GlobalState";

const Header = ({ isAuthenticated, user="User" }) => {
    const [state, setState] = useContext(GlobalState);
    // const setAuth = () => {
    //     setState({
    //         ...state,
    //         isAuthenticated: true
    //     })
    // }
    console.log(user)

    return (
        <header className={styles.header}>
            <Logo />
            <div className={styles.rightBlock}>
                <HeaderNav />
                {isAuthenticated ? <HeaderProfileLink username={state.username} /> : <HeaderButton caption={"Log In"} />}
            </div>
        </header>
    )
}

export default Header