import styles from './ChallengeDetails.module.css'
import { Breadcumb, Button, H2, H3, SpanText } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ChallengesService from "../../services/ChallengesService";

const ChallengeDetails = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)
    const history = useHistory()
    const params = useParams()

    useEffect(() => {
        try {
            const fetchData = async (challengeId) => {
                setIsLoading(true)
                const response = await ChallengesService.getChallengeInfo(challengeId)
                setData(response.data)
                console.log(response)
            }
            fetchData(params.id).then()
        } catch (e) {
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }, [])

    const getDemandFormId = (type) => {
        switch (type) {
            case "Access":
                return process.env.REACT_APP_TYPEFORM_ACCESS_DEMAND_FORM_ID
            case "Shelter":
                return process.env.REACT_APP_TYPEFORM_SHELTER_DEMAND_FORM_ID
            default:
                return process.env.REACT_APP_TYPEFORM_ACCESS_DEMAND_FORM_ID
        }
    }

    const getSoutionFormId = (type) => {
        switch (type) {
            case "Access":
                return process.env.REACT_APP_TYPEFORM_ACCESS_SOLUTION_FORM_ID
            case "Shelter":
                return process.env.REACT_APP_TYPEFORM_SHELTER_SOLUTION_FORM_ID
            default:
                return process.env.REACT_APP_TYPEFORM_ACCESS_SOLUTION_FORM_ID
        }
    }

    return (
        <>
            {data ?
                <Breadcumb links={[{
                    title: 'Challenges',
                    link: '/challenges'
                }, {
                    title: data.json["What is the Challenge's name?"],
                    link: `/challenges/${data.id}`
                }]} /> :
                null
            }
            <section className={styles.mainSection}>
                {error ?
                    <>
                        <p>An error occured on load challenge info, please, try again later</p>
                        <p>Error message: {error}</p>
                    </> :
                    isLoading ?
                        <p>Loading</p> :
                        data ?
                            <>
                                <H2>{data.json ? data.json["What is the Challenge's name?"] : 'Loading'}</H2>
                                <SpanText>{data.json ? data.json["Describe your challenge's goals in a few sentences"] : 'Loading'}</SpanText>
                                <p className={styles.detailInfo}>Core need: <span
                                    className={styles.greenText}>{data.json ? data.json["What core need does this Challenge address?"] : 'Loading'}</span></p>
                                <p className={styles.detailInfo}>Region: <span
                                    className={styles.greenText}>{data.json ? data.json["What region does this challenge address?"] : 'Loading'}</span></p>
                                {/* {data.json && data.json['Do you have an expert panel selected for this challenge'] === 'true' ?
                                    <p className={styles.detailInfo}>Experts: <span
                                        className={styles.greenText}>{data.json ? data.json["Please add the names of the expert panel that should oversee this challenge"] : 'Loading'}</span></p> :
                                    null
                                } */}
                                <div className={styles.block}>
                                    <H3>For Demand Owners</H3>
                                    <SpanText>Complete a short questionnaire to identify your main obstacles and possible climate actions related to your Shelter needs.</SpanText>
                                    <Button type={"basic"} caption={"Submit a Demand for this Challenge"}
                                        onClick={() => history.push(`/challenges/${data.json.id}/demand/${data.json.demandFormId}`)} />
                                </div>
                                <div className={styles.block}>
                                    <H3>For Solution Providers</H3>
                                    <SpanText>Submit a your proposed solution to help cities and other entities solve their Shelter and other related needs. </SpanText>
                                    <Button type={"basic"} caption={"Submit a Solution for this Challenge"}
                                        onClick={() => history.push(`/challenges/${data.json.id}/solution/${data.json.solutionFormId}`)} />
                                </div>
                            </> :
                            null
                }
            </section>
        </>
    )
}

export default ChallengeDetails