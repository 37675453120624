// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderButton_headerButton__2gxb8 {\n    background: rgba(0,0,0,0);\n    height: 40px;\n    border: 1px solid #FFFFFF;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 24px;\n    font-family: Poppins, \"Roboto\", sans-serif;\n    color: #FFFFFF;\n    padding: 5px 30px;\n    border-radius: 100px;\n}", "",{"version":3,"sources":["webpack://src/components/HeaderButton/HeaderButton.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,0CAA0C;IAC1C,cAAc;IACd,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".headerButton {\n    background: rgba(0,0,0,0);\n    height: 40px;\n    border: 1px solid #FFFFFF;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 24px;\n    font-family: Poppins, \"Roboto\", sans-serif;\n    color: #FFFFFF;\n    padding: 5px 30px;\n    border-radius: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerButton": "HeaderButton_headerButton__2gxb8"
};
export default ___CSS_LOADER_EXPORT___;
