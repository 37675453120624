// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Breadcumb_breadcumb__rIwiY {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding-left: 10px;\n    padding-right: 10px;\n    border-bottom: 1px solid #ECECEC;\n}\n\n.Breadcumb_breadcumb__rIwiY a {\n    padding: 10px;\n    font-family: Source Sans Pro, sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n    color: #333333;\n    text-decoration: none;\n}\n\n.Breadcumb_breadcumb__rIwiY span {\n    margin-left: 10px;\n    margin-right: 10px;\n}\n\n.Breadcumb_breadcumb__rIwiY a:last-of-type {\n    font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/components/Breadcumb/Breadcumb.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,wCAAwC;IACxC,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".breadcumb {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding-left: 10px;\n    padding-right: 10px;\n    border-bottom: 1px solid #ECECEC;\n}\n\n.breadcumb a {\n    padding: 10px;\n    font-family: Source Sans Pro, sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 20px;\n    color: #333333;\n    text-decoration: none;\n}\n\n.breadcumb span {\n    margin-left: 10px;\n    margin-right: 10px;\n}\n\n.breadcumb a:last-of-type {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcumb": "Breadcumb_breadcumb__rIwiY"
};
export default ___CSS_LOADER_EXPORT___;
