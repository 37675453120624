import styles from './CredentialsPage.module.css'
import {CredentialsConnections, CredentialsHome, SideNav, UserCredentials} from "../../components";
import {useState} from "react";

const Credentials = () => {
    const [pages, setPages] = useState([{
            name: 'home',
            isActive: true,
            component: <CredentialsHome/>
        }, {
            name: 'connections',
            isActive: false,
            component: <CredentialsConnections/>
        }, {
            name: 'credentials',
            isActive: false,
            component: <UserCredentials/>
        }]
    );

    const setActivePage = (pageName) => {
        setPages(pages.map(page => {
            if(page.name === pageName) {
                return {...page, isActive: true}
            } else {
                return {...page, isActive: false}
            }
        }))
    }

    return (
        <section className={styles.mainSection}>
            <SideNav pages={pages} setActivePage={setActivePage}/>
            {pages.map(page => {
                if (page.isActive) {
                    return page.component
                }
            })}
        </section>
    )
}

export default Credentials
