import {useContext} from "react";
import GlobalState from "../context/GlobalState";
import {Redirect, Route, Switch} from "react-router-dom";
import {
    ChallengeDetails,
    Challenges, CredentialsPage, DemandReview, Explore,
    Landing,
    LandingLogged, MatchedSolutions,
    TypeformCreateChallenge,
    TypeformDemand,
    TypeformSolution
} from "../pages";
import {Footer, Header, Navigation} from "./index";

const AppRouter = () => {
    const [state] = useContext(GlobalState);

    if (!state.isAuthenticated) {
        return (
            <Switch>
                <Route path={"/"} exact>
                    <Landing/>
                </Route>
                <Redirect to={"/"}/>
            </Switch>
        )
    }

    return (
        <>
            <Header isAuthenticated={true}/>
            <main>
                <Navigation/>
                <Switch>
                    <Route path={"/"} exact>
                        <LandingLogged/>
                    </Route>
                    <Route path={"/challenges"} exact>
                        <Challenges isAdmin={state.isAdmin}/>
                    </Route>
                    <Route path={"/challenges/create"} exact>
                        {state.isAdmin ?
                            <TypeformCreateChallenge/> :
                            <Redirect to={"/challenges"}/>
                        }
                    </Route>
                    <Route path={"/challenges/:id"} exact>
                        <ChallengeDetails/>
                    </Route>
                    <Route path={"/challenges/:id/demand"} exact>
                        <TypeformDemand />
                    </Route>
                    <Route path={"/challenges/:id/demand/review"} exact>
                        <DemandReview />
                    </Route>
                    <Route path={"/challenges/:id/demand/review/proposedsolutions"} exact>
                        <MatchedSolutions />
                    </Route>
                    <Route path={"/challenges/:id/solution"} exact>
                        <TypeformSolution />
                    </Route>
                    <Route path={"/explore"} exact>
                        <Explore />
                    </Route>
                    <Route path={"/credentials"} exact>
                        <CredentialsPage />
                    </Route>
                    <Redirect to={"/"}/>
                </Switch>
            </main>
            <Footer/>
        </>
    )
}

export default AppRouter