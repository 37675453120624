import styles from './LoginWithCredentials.module.css'
// import code from './qrcode 1.png'
import QR from 'qrcode.react'

const LoginWithCredentials = (props) => {
    return (
        <section className={styles.modalContent}>
            <span className={styles.title}>Log in with your verifiable credential</span>
            {/* <img src={code} alt="" className={styles.code}/> */}
            {props.QRCodeURL ? (
                <div className="qr">
                    <p>
                        <QR
                            value={props.QRCodeURL}
                            size={300}
                            renderAs="svg"
                        />
                    </p>
                </div>
            ) : (
                <p>
                    <span>Loading...</span>
                </p>
            )}
            <span className={styles.helper}>Scan QR code with your credential wallet. <br />Make sure to set your wallet to the Indicio Test network</span>
            <span className={styles.helper2}>How does this work?</span>
        </section>
    )
}

export default LoginWithCredentials