// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserCredentials_content__1yQqH {\n    background: rgba(81, 145, 206, 0.21);\n    width: 100%;\n    padding: 50px 65px;\n}\n\n.UserCredentials_content__1yQqH h2 {\n    margin-bottom: 15px;\n}\n\n.UserCredentials_content__1yQqH > span {\n    margin-bottom: 30px;\n}\n\n.UserCredentials_cards__38LU4 {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-top: 30px;\n}", "",{"version":3,"sources":["webpack://src/components/UserCredentials/UserCredentials.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".content {\n    background: rgba(81, 145, 206, 0.21);\n    width: 100%;\n    padding: 50px 65px;\n}\n\n.content h2 {\n    margin-bottom: 15px;\n}\n\n.content > span {\n    margin-bottom: 30px;\n}\n\n.cards {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "UserCredentials_content__1yQqH",
	"cards": "UserCredentials_cards__38LU4"
};
export default ___CSS_LOADER_EXPORT___;
