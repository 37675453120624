// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderNav_header__nav__2aInG {\n    font-family: Poppins, \"Roboto\", sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.HeaderNav_header__list__1dtJf {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    list-style-type: none;\n}\n\n.HeaderNav_header__link_combined__2xSuI {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.HeaderNav_header__link_combined__2xSuI svg {\n    margin-left: 5px;\n}\n\n.HeaderNav_header__list__1dtJf li:not(:last-child) {\n    margin-right: 25px;\n}", "",{"version":3,"sources":["webpack://src/components/HeaderNav/HeaderNav.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".header__nav {\n    font-family: Poppins, \"Roboto\", sans-serif;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.header__list {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    list-style-type: none;\n}\n\n.header__link_combined {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.header__link_combined svg {\n    margin-left: 5px;\n}\n\n.header__list li:not(:last-child) {\n    margin-right: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header__nav": "HeaderNav_header__nav__2aInG",
	"header__list": "HeaderNav_header__list__1dtJf",
	"header__link_combined": "HeaderNav_header__link_combined__2xSuI"
};
export default ___CSS_LOADER_EXPORT___;
