import styles from './Challenges.module.css'
import { Button, ChallengeCard, H2 } from "../../components";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import ChallengesService from "../../services/ChallengesService";
import GlobalState from "../../context/GlobalState";
import { useContext } from 'react'

const Challenges = ({ isAdmin }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [activeChallenges, setActiveChallenges] = useState(null)
    const [inactiveChallenges, setInactiveChallenges] = useState(null)

    const [state] = useContext(GlobalState);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const data = await ChallengesService.getChallenges()
                setActiveChallenges(data.data.json.filter(item => item.isActive))
                setInactiveChallenges(data.data.json.filter(item => !item.isActive))
                // console.log(challenges)
            } catch (e) {
                setError(e.message)
            } finally {
                setIsLoading(false)
            }

        }
        fetchData().then()
    }, [])

    const history = useHistory()
    const toCreateChallenge = () => {
        history.push('/challenges/create')
    }

    return (
        <section className={styles.mainSection}>
            {state.roles.includes('admin') ?
                <div className={styles.createChallengeLine}>
                    <Button caption={"Create a Challenge"} type={"basic"} onClick={toCreateChallenge} />
                </div> :
                null
            }
            <H2>Active Challenges</H2>
            <div className={styles.cardsLine}>
                {isLoading ?
                    <p>Loading</p> :
                    error ?
                        <>
                            <p>An error occured on loading challenges, please, try again later</p>
                            <p>Error message: ${error}</p>
                        </> :
                        null
                }
                {activeChallenges ?
                    activeChallenges.map(challenge => (
                        <ChallengeCard key={challenge.id} name={challenge["What is the Challenge's name?"]} desc={challenge["Describe your challenge's goals in a few sentences"]}
                            coreNeed={challenge["What core need does this Challenge address?"]} isActive={true} region={challenge['What region does this challenge address?']} id={challenge.id} lastUpdate={challenge.Modified} type={challenge["What core need does this Challenge address?"]}
demandFormId={challenge.demandFormId} solutionFormId={challenge.solutionFormId} />)) :
                    null
                }

            </div>
            <H2>Inative Challenges</H2>
            <div className={styles.cardsLine}>
                {isLoading ?
                    <p>Loading</p> :
                    error ?
                        <>
                            <p>An error occured on loading challenges, please, try again later</p>
                            <p>Error message: ${error}</p>
                        </> :
                        null
                }
                {inactiveChallenges ?
                    inactiveChallenges.map(challenge => (
                        <ChallengeCard key={challenge.id} name={challenge["What is the Challenge's name?"]} desc={challenge["Describe your challenge's goals in a few sentences"]}
                            coreNeed={challenge["What core need does this Challenge address?"]} isActive={false} region={challenge['What region does this challenge address?']} id={challenge.id} lastUpdate={challenge.Modified} type={challenge["What core need does this Challenge address?"]} />)) :
                    null
                }
            </div>
        </section>
    )
}

export default Challenges