// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Demands_mainSection__1s7Oc {\n    padding: 85px 150px;\n}\n\n.Demands_createChallengeLine__1_kDH {\n    display: flex;\n    flex-direction: row-reverse;\n    width: 100%;\n}\n\n.Demands_cardsLine__1G2L2 {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    /*justify-content: space-between;*/\n    align-items: center;\n    width: 100%;\n    margin-top: 25px;\n    grid-column-gap: 20px;\n    column-gap: 20px;\n    grid-row-gap: 20px;\n    row-gap: 20px;\n    justify-content: flex-start;\n}\n\n.Demands_cardsLine__1G2L2 > div {\n    margin-bottom: 25px;\n}", "",{"version":3,"sources":["webpack://src/pages/Demands/Demands.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kCAAkC;IAClC,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,qBAAgB;IAAhB,gBAAgB;IAChB,kBAAa;IAAb,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".mainSection {\n    padding: 85px 150px;\n}\n\n.createChallengeLine {\n    display: flex;\n    flex-direction: row-reverse;\n    width: 100%;\n}\n\n.cardsLine {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    /*justify-content: space-between;*/\n    align-items: center;\n    width: 100%;\n    margin-top: 25px;\n    column-gap: 20px;\n    row-gap: 20px;\n    justify-content: flex-start;\n}\n\n.cardsLine > div {\n    margin-bottom: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSection": "Demands_mainSection__1s7Oc",
	"createChallengeLine": "Demands_createChallengeLine__1_kDH",
	"cardsLine": "Demands_cardsLine__1G2L2"
};
export default ___CSS_LOADER_EXPORT___;
