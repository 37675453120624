// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CredentialsPage_mainSection__2_4OO {\n    display: flex;\n    flex-direction: row;\n    min-height: inherit;\n\n}", "",{"version":3,"sources":["webpack://src/pages/CredentialsPage/CredentialsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;;AAEvB","sourcesContent":[".mainSection {\n    display: flex;\n    flex-direction: row;\n    min-height: inherit;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainSection": "CredentialsPage_mainSection__2_4OO"
};
export default ___CSS_LOADER_EXPORT___;
