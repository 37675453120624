import styles from "./LandingLogged.module.css";
import { Button, ChallengeCard, H2, SpanText } from "../../components";
import { NavLink, useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ChallengesService from "../../services/ChallengesService";
import GlobalState from "../../context/GlobalState";
import { useCookies } from 'react-cookie';

const LandingLogged = ({ logoutDemo }) => {
    const history = useHistory()
    const [state] = useContext(GlobalState);
    // const [envisionCookies, setCookie] = useCookies(['user']);
    const [error, setError] = useState(null)
    const [isChallengesLoading, setIsChallengesLoading] = useState(null)

    const [challenges, setChallenges] = useState(null)

    useEffect(() => {
        const fetchChallenges = async () => {
            try {
                setIsChallengesLoading(true)
                const response = await ChallengesService.getChallenges()
                console.log(response.data)
                setChallenges(response.data.json.filter(item => item.isActive))
            } catch (e) {
                setError(e.message)
            } finally {
                setIsChallengesLoading(false)
            }
        }
        fetchChallenges().then()
    }, [])

    return (
        <section className={styles.mainSection}>
            <p className={styles.welcomeText}>Welcome, {state.username}</p>
            {
                state.isDemo ?
                    <p>You logged as demo user. <Button caption={"Click here, to logout"} type={"linkLogout"} onClick={() => logoutDemo(history)} /></p> :
                    null
            }
            <H2>About this DEMO</H2>
            <SpanText>This is a demo site for the UN Climate Change Global Innovation Hub, which aims to match cities’ core needs with possible solutions.
                <br />
                <b>This site is for illustrative purposes only</b>, to showcase the use of credentials for verification and to explore the functionality of questionnaires to identify core needs and their specifications.
            </SpanText>
            <H2>Active Challenges</H2>
            <NavLink className={styles.link} to={"/challenges"}>See all challenges > </NavLink>
            <div className={styles.cardsLine}>
                {error ?
                    <p>An error occured on loading data. Please, try again later</p> :
                    isChallengesLoading ?
                        <p>Loading challenges</p> :
                        challenges ?
                            challenges.map(item => (
                                <ChallengeCard key={item.id} id={item.id} coreNeed={item['What core need does this Challenge address?']}
                                    type={item['What core need does this Challenge address?']}
                                    desc={item["Describe your challenge's goals in a few sentences"]} name={item["What is the Challenge's name?"]} lastUpdate={item.Modified}
                                    region={item['What region does this challenge address?']}
                                    isActive={true}
					demandFormId={item.demandFormId} solutionFormId={item.solutionFormId}
                                /> 
                            )) :
                            null
                }
            </div>
        </section>
    )
}

export default LandingLogged